// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-about-us-template-js": () => import("./../../../src/templates/AboutUsTemplate.js" /* webpackChunkName: "component---src-templates-about-us-template-js" */),
  "component---src-templates-blog-page-template-js": () => import("./../../../src/templates/BlogPageTemplate.js" /* webpackChunkName: "component---src-templates-blog-page-template-js" */),
  "component---src-templates-contact-template-js": () => import("./../../../src/templates/ContactTemplate.js" /* webpackChunkName: "component---src-templates-contact-template-js" */),
  "component---src-templates-general-template-js": () => import("./../../../src/templates/GeneralTemplate.js" /* webpackChunkName: "component---src-templates-general-template-js" */),
  "component---src-templates-home-template-js": () => import("./../../../src/templates/HomeTemplate.js" /* webpackChunkName: "component---src-templates-home-template-js" */),
  "component---src-templates-post-template-js": () => import("./../../../src/templates/PostTemplate.js" /* webpackChunkName: "component---src-templates-post-template-js" */),
  "component---src-templates-service-template-js": () => import("./../../../src/templates/ServiceTemplate.js" /* webpackChunkName: "component---src-templates-service-template-js" */),
  "component---src-templates-services-template-js": () => import("./../../../src/templates/ServicesTemplate.js" /* webpackChunkName: "component---src-templates-services-template-js" */)
}

